.app {
  background-color: #000;
  color: #fff;
  padding-top: 120px;
  position: relative;
}

html {
  scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
}