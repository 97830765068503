header {
    position: fixed;
    top: 0;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    height: 120px;
    backdrop-filter: blur(10px);
    align-items: center;
    font-size: 25px;
    text-transform: uppercase;
    cursor: pointer;
    z-index: 1;
    font-family: PT Serif, serif;

    .logo {
        margin-left: 100px;
        font-weight: 500;
        color: #004aad;
    }

    .menu {
        display: flex;
        column-gap: 50px;
        margin: 50px;
        margin-right: 100px;
    }

    span:hover {
        color: #004aad;
    }

    @media only screen and (max-width: 1250px) {
        .logo {
            margin-left: auto;
            margin-right: 70px;
        }
        .menu {
            display: none;
        }
    }
}