.home-page {

    .home-section {
        display: grid;
        place-items: center;
        height: calc(100vh - 120px);
    
        span {
            font-size: 80px;
            font-family: Inconsolata, monospace;
            text-align: center;

            @media only screen and (max-width: 1250px) {
                font-size: 40px;
            }
        }
    }
}